<template>
    <div class="store-wrapper">
        <el-scrollbar class="store-container" :native="false">
            <div class="web-top-wrapper">
                <div class="web-top">
                    <div class="top-left">
                        <!--<div class="web-logo">-->
                            <!--<img :src="require('../../../assets/images/jd-logo.png')" alt="">-->
                        <!--</div>-->
                        <el-select v-model="classValue" placeholder="全部分类" style="width: 110px">
                            <el-option v-for="item in classList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="top-search">
                        <el-input v-model="search" placeholder="请输入搜索内容" style="width: 480px;"></el-input>
                        <div class="btn search-all">搜全站</div>
                        <div class="btn search-current">搜本站</div>
                    </div>
                </div>
            </div>
            <div class="web-tab-wrapper">
                <div class="web-tab">{{goodInfo.store_class}}</div>
            </div>
            <div class="good-info-wrapper">
                <div class="good-info">
                    <div class="good-cover">
                        <div class="video-master" v-show="playStatus">
                            <div class="stop-icon-box" @click="playPause">
                              <i class="el-icon-close"></i>
                            </div>
                            <video ref="videoRef" class="video-js vjs-default-skin">
                              <p class="vjs-no-js">要观看此视频，请启用 JavaScript，并考虑升级到支持 HTML5 视频的 Web 浏览器。</p>
                            </video>
                        </div>
                        <swiper ref="mySwiper" :options="swiperOptions" class="store-banner" v-show="!playStatus && goodsVideo">
                            <swiper-slide class="item" v-for="(imgItem, imgIndex) in goodInfo.goods_image" :key="`goods_image_${imgIndex}`">
                              <div class="img-box">
                                <div class="play-box" v-if="imgIndex === 0 && goodsVideo" @click="playVideo">
                                  <span class="video-icon"></span>
                                </div>
                                <img :src="imgItem.image_url">
                              </div>
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                        <!--                        <div class="swiper-container" v-swiper:mySwiper="swiperOptions">-->
                        <!--                            <div class="swiper-wrapper">-->
                        <!--                                <div class="swiper-slide"-->
                        <!--                                     v-for="(imgItem, imgIndex) in goodInfo.goods_image" :key="`goods_image_${imgIndex}`">-->
                        <!--                                    <img :src="imgItem.image_url">-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="swiper-pagination"></div>-->
                    </div>
                    <div class="right-content">
                        <p class="good-title">{{goodInfo.goods_name}}</p>
                        <div class="good-price">
                            <div class="price">
                                <span class="text">价格</span>
                                <span class="num">&yen;{{goodInfo.goods_price}}</span>
                            </div>
                            <div class="sales-price">
                                <span class="text">折扣价</span>
                                <span class="num">&yen;{{goodInfo.goods_zhekou_price}}</span>
                            </div>
                        </div>
                        <div class="good-address">
                            <span class="text">配送   至</span>
                            <el-select v-model="addressList" placeholder="请选择地区" style="width: 110px" size="mini">
                                <el-option v-for="item in classList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                            <span class="text">有货</span>
                            <span class="text">免运费</span>
                        </div>
                        <div class="good-color">
                            <span class="text">颜色</span>
                            <div class="color-content">
                                <div class="color-img">
                                    <img :src="goodInfo.goods_image.length > 0 ? goodInfo.goods_image[0].image_url : require('../../../assets/images/noimg.png')" alt="">
                                </div>
                                <div class="color-text">颜色</div>
                            </div>
                        </div>
                        <div class="good-num">
                            <el-input-number v-model="goodInfo.goodNum" size="small" :min="1" label="描述文字"></el-input-number>
                        </div>
                        <div class="good-btn">
                            <el-button class="btn-red-dark">加入购物车</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="web-tab-wrapper" style="margin-top: 30px">
                <div class="web-tab">
                    <div class="tab-item">商品详情</div>
                    <div class="detail-content" v-html="goodInfo.goods_content"></div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import videojs from "video.js";

export default {
    name: 'Goods',
    data() {
        return {
          goodsVideo: "",
          player: null,
          playStatus: false,
            goodsId: Number(this.$route.query.id) || null,
            search: '',
            classList: [
              {
                value: '1',
                label: '全部地区'
              },
              // {
              //     value: '2',
              //     label: '卷发棒'
              // }, {
              //     value: '3',
              //     label: '直发棒'
              // }, {
              //     value: '4',
              //     label: '蛋卷棒'
              // }
            ],
            classValue: '全部分类',
            goodInfo: {
                id: null,
                goods_name: '',
                goods_image: [],
                goods_price: '',
                goods_zhekou_price: '',
                store_class: '',
                store_class_id: '',
                goods_content: '',
                // goodCover: [
                //     {
                //         img: 'https://img11.360buyimg.com/n1/jfs/t1/103235/26/14657/496206/5e68a089E8df12e15/3d25a0cf448b542f.jpg',
                //     },
                //     {
                //         img: 'https://img11.360buyimg.com/n1/jfs/t1/104367/5/14844/491273/5e68a088E586f602a/91a3d101eb65d92f.jpg',
                //     },
                //     {
                //         img: 'https://img11.360buyimg.com/n1/jfs/t1/104367/5/14844/491273/5e68a088E586f602a/91a3d101eb65d92f.jpg',
                //     },
                //     {
                //         img: 'https://img11.360buyimg.com/n1/jfs/t1/103235/26/14657/496206/5e68a089E8df12e15/3d25a0cf448b542f.jpg',
                //     },
                //     {
                //         img: 'https://img11.360buyimg.com/n1/jfs/t1/102756/30/14651/190335/5e68a08eE68e2bba5/d86517c7a5328314.jpg',
                //     },
                // ],
                goodNum: 1,
            },
            addressList: '请选择地区',
            swiperOptions: {
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: (index, className) => {
                        if (this.goodInfo.goods_image.length === 0) {
                            return ''
                        }
                        return `<span class="${className}"><img src="${this.goodInfo.goods_image[index].image_url}"></span>`
                    },
                },
            },
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    mounted() {
        this.getGoodsInfo();
    },
    methods: {
      playPause() {
        this.playStatus = false;
        this.player.pause()
        // this.player.dispose()
      },
      playVideo() {
        this.playStatus = true;
        this.player.currentTime(0)
        this.player.play()
      },
      videoInit() {
        if (this.goodsVideo) {
          this.player = videojs(this.$refs.videoRef, {
            controls: true,
            autoplay: false,
            height: "350",
            width: "350",
            sources: [
              {
                src: this.goodsVideo,
                type: 'video/mp4'
              }
            ],
          }, () => {
            this.player.log("onPlayerReady", this)
          })
        }
      },
        //请求商品详情
        getGoodsInfo(){
            let params = {
                goods_id: this.goodsId
            }
            this.$http.axiosGetBy(this.$api.decorateGoodInfo, params, (res) => {
                if (res.code === 200) {
                    this.goodInfo = res.data
                  this.goodsVideo = res.data.goods_video
                    this.goodInfo.goodNum = 1
                  this.videoInit()
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                console.log(err)
            })
        },
      beforeDestroy() {
        if (this.player) {
          this.player.dispose()
        }
      }

    }
}
</script>

<style scoped lang="scss">
.video-master {
  position: relative;
  .stop-icon-box {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 26px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: #8C8C8C;
      font-size: 20px;
    }
  }
}
.img-box {
  position: relative;
  .play-box {
    z-index: 999;
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span.video-icon {
      width: 50px;
      height: 50px;
      background: url("../../../assets/images/main-circles.png") 0 -55px no-repeat;
      &:hover {
        background: url("../../../assets/images/main-circles.png") -55px -55px no-repeat;
      }
    }
  }

}
.store-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #fff;
}
.store-container {
    min-width: 1200px;
    height: 100vh;
    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
            /*min-width: 1200px;*/
        }
    }
    .web-top-wrapper {
        .web-top {
            width: 1200px;
            height: 106px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .top-left {
                display: flex;
                .web-logo {
                    width: 134px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                /deep/ .el-select {
                    /*margin-left: 60px;*/
                    .el-input__inner {
                        border-radius: 0;
                    }
                }
            }
            /deep/ .top-search {
                display: flex;
                align-items: center;
                .el-input {
                    .el-input__inner {
                        border-radius: 0;
                        border-width: 2px 0 2px 2px;
                        border-color: #DF0200;
                        border-style: solid;
                    }
                }
                .btn {
                    width: 60px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                    &.search-all {
                        background: #B61D1D;
                    }
                    &.search-current {
                        background: #474E5C;
                    }
                }
            }
        }
    }
    .web-tab-wrapper {
        background: #F2F2F2;
        .web-tab {
            width: 1200px;
            margin: 0 auto;
            height: 40px;
            line-height: 40px;
            color: #666;
            .tab-item {
                color: #fff;
                background: #B61D1D;
                width: 108px;
                text-align: center;
            }
        }
    }
    .good-info-wrapper {
        margin-top: 20px;
        .good-info {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            .good-cover {
                position: relative;
                .swiper-container {
                    width: 350px;
                    height: 450px;
                    .swiper-slide {
                        width: 350px!important;
                        /*height: 560px;*/
                        height: 350px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #fafafa;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                            /*width: 100%;*/
                            /*height: 100%;*/
                        }
                    }
                }
                /deep/ .swiper-pagination {
                    width: 350px;
                    display: flex;
                    margin: 20px -5px;
                    bottom: 0;
                    .swiper-pagination-bullet {
                        /*width: 96px;*/
                        width: 60px;
                        height: 60px;
                        background: #fafafa;
                        border: 2px solid transparent;
                        box-sizing: border-box;
                        margin: 0 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 1;
                        border-radius: 0;
                        img {
                            display: block;
                            max-width: 100%;
                            max-height: 100%;
                        }
                        &.swiper-pagination-bullet-active {
                            border-color: #B61D1D;
                        }
                    }
                }
            }
            .right-content {
                margin-left: 40px;
                width: 1%;
                flex: 1;
                .good-title {
                    font-size: 16px;
                    font-weight: bold;
                }
                .good-price {
                    margin-top: 20px;
                    background: #F7F7F7;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    padding-left: 16px;
                    .price {
                        color: #999;
                        .text {

                        }
                        .num {
                            margin-left: 10px;
                            font-size: 16px;
                            text-decoration: line-through;
                        }
                    }
                    .sales-price {
                        margin-left: 30px;
                        .text {
                            color: #999;
                        }
                        .num {
                            margin-left: 10px;
                            color: #FF0000;
                            font-size: 16px;
                        }
                    }
                }
                .good-address {
                    margin-top: 20px;
                    color: #999;
                    .text {
                        margin-right: 20px;
                    }
                    .el-select {
                        margin-right: 20px;
                    }
                }
                .good-color {
                    margin-top: 20px;
                    display: flex;
                    .text {
                        color: #999;
                    }
                    .color-content {
                        flex: 1;
                        width: 1%;
                        margin-left: 20px;
                        display: flex;
                        .color-img {
                            width: 60px;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 1px solid #B61D1D;
                            box-sizing: border-box;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .color-text {
                            margin-left: -1px;
                            border: 1px solid #B61D1D;
                            box-sizing: border-box;
                            background: #F7F7F7;
                            width: 60px;
                            height: 60px;
                            text-align: center;
                            line-height: 60px;
                        }
                    }
                }
                .good-num {
                    margin-top: 20px;
                }
                .good-btn {
                    margin-top: 20px;
                }
            }
        }
    }
    .detail-content {
        margin-top: 20px;
        text-align: center;
        img {
            max-width: 100%;
            display: inline-block;
            margin-top: 20px;
        }
    }
}
/deep/ p {
    margin: 0;
}
.btn-red-dark {
    width: 140px;
    height: 46px;
    border-radius: 0;
    background: #B61D1D;
    border-color: #B61D1D;
    color: #fff;
}
</style>